<template lang="pug">
    div.pa-10
        PageTitle(:label="protocol.id ? `Editar Protocolo - #${protocol.id}` : 'Novo Protocolo'")
            v-btn( color="success" v-if="protocol.id" style="float: right" :href="`${api.url}/protocol/${protocol.id}/print`" target="_blank") Imprimir
                v-icon(right) mdi-printer
        
        div(v-acl.cant.hide='protocol.id ? `protocol.edit` : `protocol.add`') Você não possui a permissão necessária para acessar o recurso
        div(v-acl.can.hide='protocol.id ? `protocol.edit` : `protocol.add`')

            v-form(v-model="valid")
                
                form-client(v-model="protocol.client" ref="formClient")

                v-container( fluid )
                    h3.mb-10.mt-5 Informações
                    v-textarea(
                        v-model="protocol.description"
                        label="Descrição"
                        v-bind="{outlined: true, readonly: false}"
                    )


                v-container( fluid )
                    h3.mb-10.mt-5 Pagamento
                    .row
                        div( :class="{ 'col-md-6' : !needChange, 'col-md-4' : needChange }")
                            v-text-field-money(
                                v-model="protocol.total"
                                label="Valor Total"
                                v-bind:properties="{ prefix: 'R$', readonly: false, outlined: true, clearable: true, placeholder: ' ',}"
                            )

                        div( :class="{ 'col-md-6' : !needChange, 'col-md-4' : needChange }")
                            v-select( :items="config.PAYMENT_METHODS" item-text="label" item-value="type" v-model="protocol.payment_method" label="Forma de Pagamento" outlined)

                        .col-md-2(v-if="needChange")
                            v-text-field-money(
                                v-model="protocol.change_for"
                                label="Troco para"
                                v-bind:properties="{ prefix: 'R$', readonly: false, outlined: true, clearable: true, placeholder: ' ',}"
                                )
                        
                        .col-md-2(v-if="needChange")
                            v-text-field( :value="change | money" readonly disabled label="Troco" outlined)
                
                v-btn( color="success" x-large @click="sendProtocol" :disabled="onSave" :loading="onSave") Salvar
                                v-icon(right) mdi-content-save

                    
        
</template>

<script>

class Protocol {
    id = null;
    client = null;
    description = null;
    payment_method = null;
    change = null;
    change_for = null;
    total = null;
}

export default {
  name: "ProtocolForm",
  components: {
      FormClient: () => import( '@/views/Clients/components/FormClient' ),
      'v-text-field-money' : () => import( '@/components/form/InputMoney' ),
      PageTitle: () => import( '@/components/PageTitle' ),
  },
  data: function(){
      return {
          onSave: false,
          valid: false,
          protocol: new Protocol(),
      }
  },

    mounted: function(){
        if( this.$route.params.id ){
            this.protocol.id = this.$route.params.id
            this.loadData()
        }
    },

    watch: {
        'change' : function() {
            this.protocol.change = this.change;
        },
    },

    computed: {
        'change' : function() {
            return  ( parseFloat(this.protocol.change_for) > parseFloat(this.protocol.total)  ) ? (parseFloat(this.protocol.change_for) - parseFloat(this.protocol.total) ) : 0;
        },

        'needChange' : function() {
            return this.protocol.payment_method === 2 || this.protocol.payment_method === 3; // Dinheiro ou Cheque
        },
    },

    methods:{
        loadData: async function(){
            let response = await this.api.get( `protocol/${this.$route.params.id}` )
            let protocol = response.data
            this.$refs.formClient.getById( protocol.client_id )
            
            this.protocol = protocol;
        },
        
       
        sendProtocol: function(){

            let self = this;
            
            this.onSave = true;


            if( !this.protocol.id ){
                // New
                this.api.post( 'protocol', this.protocol ).then(function( response ){
                    self.onSave = false;
                    if( response ){
                        self.$root.toast.show( {message: "Protocolo criado com sucesso!" } )
                        window.scrollTo({ top: 0, behavior: 'smooth' })
                        setTimeout(function(){
                            self.$router.push( { name: 'protocols-edit', params: { id: response.data.id } })
                        }, 1000)
                        
                        
                    }
                        
                })
            }else{
                // update

                this.api.put( `protocol/${this.$route.params.id}`, this.protocol ).then(function( response ){
                    self.onSave = false;
                    if( response )
                        self.$root.toast.show( {message: "Protocolo editado com sucesso!" } )
                })
            }
        },
        
    },
};
</script>
